import React, {useEffect, useMemo, useState} from "react";
import {Omniclass} from "@/types/Omniclass";

export const filterOmniclassOptions = (options: Omniclass[], inputValue: string) => {
  if (!inputValue) return options;
  return options.filter(option => {
      if (option.number) {
        return option.name.toLowerCase().includes(inputValue.toLowerCase()) || option.number.toLowerCase().includes(inputValue.toLowerCase())
      }
      return option.name.toLowerCase().includes(inputValue.toLowerCase())
    }
  );
}

export const filterManufacturerOptions = (options: string[], inputValue: string) => {
  if (!inputValue) return options;
  return options.filter(option =>
    option.toLowerCase().includes(inputValue.toLowerCase())
  );
}

export function useSearchInput<T>(
  fetchOptions: () => Promise<T[]>,
  filterOptions: (options: T[], inputValue: string) => T[],
  setSelectedOption?: (option: T | null) => void,
) {
  const [options, setOptions] = useState<T[]>([]);
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    fetchOptions()
      .then(fetchedOptions => {
        setOptions(fetchedOptions);
      }).then(() => setLoading(false))
      .catch(error => console.error('Error fetching options:', error));
  }, [fetchOptions]);

  const filteredOptions = useMemo(() => {
    return filterOptions(options, inputValue);
  }, [options, inputValue]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    setShowButton(!!value);
    setIsOpen(true);

    if (setSelectedOption) setSelectedOption(null);
  };

  return {
    options,
    loading,
    inputValue,
    isOpen,
    showButton,
    setOptions,
    setLoading,
    setInputValue,
    setIsOpen,
    setShowButton,
    filteredOptions,
    handleInputChange,
  }
}
