import { useState, useEffect } from 'react';
import { OmniclassTableInfo } from "@/types/OmniclassTableInfo";
import { useSelectedParameters } from "../../../hooks/useSelectedParameters";
import { useManufacturers } from "../../../hooks/useManufacturers";
import { useManufacturerSelection } from "./useManufacturerSelection";
import {useSaveConfig} from "@/app/hooks/useSaveConfig";

const LOCAL_STORAGE_KEY = 'omniclassConfiguration';

export const useResultsContainer = (tableInfo: OmniclassTableInfo) => {
  const { selectedValues, handleSelectValue, setSelectedValues } = useSelectedParameters(tableInfo.parameters);
  const {
    manufacturers,
    loading,
    expanded,
    setExpanded,
    fetchManufacturers,
    updateManufacturers,
    refreshManufacturers,
    shouldHighlight,
    changeSortMethod
  } = useManufacturers(tableInfo);
  const { selectedManufacturers, setSelectedManufacturers, handleSelectManufacturer, hasReachedMaxManufacturerSelection } = useManufacturerSelection();

  // this state is controlled by the `ParameterSection`, then used by the `ValueSection`
  const [selectedParameterName, setSelectedParameterName] = useState<string | undefined>(undefined);

  // the `ValueSection` represents the selected parameter represented by `selectedParameterName`
  const selectedParameter = tableInfo.parameters.find(parameter => parameter.name === selectedParameterName);

  const showExpandButton = !!tableInfo.number;

  // used to prevent calling functions before the component is mounted
  const [isMounted, setIsMounted] = useState(false);

  useSaveConfig(isMounted, tableInfo, selectedValues, setSelectedValues, selectedManufacturers, setSelectedManufacturers);

  /**
   * Update manufacturers when the selected parameters change
   *
   * This is restricted to only run after the component is mounted.
   */
  useEffect(() => {
    if (isMounted) {
      // return the cleanup function (which aborts the fetch request if another one is made)
      return updateManufacturers(selectedValues);
    }
  }, [selectedValues]);

  // fetch manufacturers on initial load and set isMounted to true
  useEffect(() => {
    fetchManufacturers();
    setIsMounted(true);
  }, []);

  /**
   * Fetch manufacturers and filter when the expanded state changes
   *
   * This is restricted to only run after the component is mounted.
   */
  useEffect(() => {
    if (isMounted) {
      refreshManufacturers(selectedValues).then(() => {
        console.log('refreshed manufacturers');
      })
    }
  }, [expanded]);

  return {
    selectedValues,
    handleSelectValue,
    manufacturers,
    loading,
    shouldHighlight,
    changeSortMethod,
    selectedManufacturers,
    handleSelectManufacturer,
    hasReachedMaxManufacturerSelection,
    selectedParameterName,
    setSelectedParameterName,
    selectedParameter,
    expanded,
    setExpanded,
    showExpandButton
  };
};