import {useEffect} from "react";
import {ManufacturerSpecificVariant, OmniclassTableInfo} from "@/types/OmniclassTableInfo";
import {SelectedParameter} from "@/types/Parameters";

const LOCAL_STORAGE_KEY = 'omniclassConfiguration';

export const useSaveConfig = (
  isMounted: boolean,
  tableInfo: OmniclassTableInfo | ManufacturerSpecificVariant,
  selectedValues: SelectedParameter[],
  setSelectedValues: (selectedValues: SelectedParameter[]) => void,
  selectedManufacturers?: string[],
  setSelectedManufacturers?: (selectedManufacturers: string[]) => void,
) => {
  /**
   * Load or initialize selectedValues and selectedManufacturers from localStorage
   */
  useEffect(() => {
    const storedInfo = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedInfo) {
      const { name, selectedValues: storedSelectedValues, selectedManufacturers: storedSelectedManufacturers } = JSON.parse(storedInfo);
      if (name === tableInfo.name) {
        setSelectedValues(storedSelectedValues);

        // Only set selected manufacturers if the function is provided
        if (setSelectedManufacturers) setSelectedManufacturers(storedSelectedManufacturers);
      } else {
        // Reset if the omniclass table has changed
        localStorage.removeItem(LOCAL_STORAGE_KEY);
      }
    }
  }, []);

  /**
   * Save to configuration when selectedValues or selectedManufacturers change
   *
   * This is restricted to only run after the component is mounted.
   */
  useEffect(() => {
    if (isMounted) {
      const userInputConfig = {
        name: tableInfo.name,
        manufacturerTitle: (tableInfo as ManufacturerSpecificVariant).manufacturer_title || '',
        selectedValues,
        selectedManufacturers: selectedManufacturers || [],
      };
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userInputConfig));
    }
  }, [selectedValues, selectedManufacturers, isMounted]);
}