/// Represents a Manufacturer returned from the backend
/// This is a 1:1 representation of the Manufacturer type in the backend
export type Manufacturer = {
  title: string;
  url?: string;
}

/**
 * Represents an entry in either the `links_for_professionals` or `additional_links` fields of an `AnnotatedManufacturer`
 *
 * This is a 1:1 representation of the `SupplementalLink` type in the backend
 */
export type SupplementalLink = {
  title: string;
  url: string;
}

/**
 * Represents an entry in the `aggregator_content` field of an `AnnotatedManufacturer`
 *
 * This is a 1:1 representation of the `AggregatorContent` type in the backend, however this implementation uses
 * `string` values for the aggregator keys to account values which are empty strings.
 *
 *
 */
export type AggregatorContent = {
  architizer: number;
  bimobject: number;
  bimsmith: number
  caddetails: number;
}

export const blankAggregatorContent: AggregatorContent = {
  architizer: 0,
  bimobject: 0,
  bimsmith: 0,
  caddetails: 0
}

// Represents a `Manufacturer` with additional metadata fields
// This is a 1:1 representation of the `AnnotatedManufacturer` type in the backend
export type AnnotatedManufacturer = Manufacturer & {
  email?: string | null;
  aggregator_content?: AggregatorContent | null;
  description?: string | null;
  formatted_address?: string | null;
  place_id?: string | null;
  verified: boolean;
  links_for_professionals?: SupplementalLink[] | null;
  additional_links?: SupplementalLink[] | null;
  logo?: string | null;
}

// Represents an `AnnotatedManufacturer` plus a `highlighted` field
// This `highlighted` field is used to indicate that the manufacturer should be highlighted in the UI
// as returned by the user's parameter-value selection.
export type RenderedManufacturer = AnnotatedManufacturer & {
  highlighted?: boolean;
}