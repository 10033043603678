import {adminEndpoints, apiEndpoints} from "../endpoints";
import {AnnotatedManufacturer} from "@/types/Manufacturer";
import {SelectedParameter} from "@/types/Parameters";
import {checkSuccess} from "@/api/services/utils";


/**
 * Get `AnnotatedManufacturer` data
 *
 * @param title - manufacturer title
 */
export const getManufacturerData = async (title: string): Promise<AnnotatedManufacturer> => {
  const encodedTitle = encodeURIComponent(title)
  const response = await fetch(`${apiEndpoints.get_manufacturer_data}?title=${encodedTitle}`)
  return await response.json()
}


/**
 * Get manufacturer data for multiple manufacturers.
 *
 * This is used by the `bootstrapManufacturers` in the `useManufacturers` hook to get manufacturer data for the
 * manufacturers that are extracted from the omniclass table parameters.
 *
 * @param titles - list of manufacturer titles
 */
export const getMultipleManufacturers = async (titles: string[]): Promise<AnnotatedManufacturer[]> => {
  if (titles.length === 0) {
    return []
  }

  const encodedTitles = titles.map(title => encodeURIComponent(title))

  let url = `${apiEndpoints.get_multiple_manufacturers}?`;
  encodedTitles.forEach(title => {
    url += `q=${title}&`
  })

  const response = await fetch(url,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  return await response.json()
}


/**
 * Get a list of omniclasses associated with the given manufacturer.
 *
 * @param title - manufacturer title
 *
 * @returns {string[]} A list of omniclass names (not identifiers) associated with the given manufacturer.
 */
export const getAssociatedOmniclasses = async (title: string): Promise<string[]> => {
  const encodedTitle = encodeURIComponent(title)
  const response = await fetch(`${adminEndpoints.manufacturer.associated_omniclasses}?title=${encodedTitle}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  return await response.json()
}


/**
 * Associate omniclasses with a manufacturer.
 *
 * @param title - manufacturer title
 * @param omniclasses - list of omniclass names. Not identifiers.
 */
export const associateOmniclasses = async (title: string, omniclasses: string[]) => {
  const response = await fetch(adminEndpoints.manufacturer.associated_omniclasses, {
    method: "post",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: title,
      associations: omniclasses
    })
  })

  await checkSuccess(response)
}


/**
 * Get a filtered list of manufacturers based on the selected parameter-values.
 *
 * The endpoint returns an empty list of manufacturers when:
 * - no manufacturers are found
 * - an exception is raised in the backend
 *
 * @param {{name: string, value: string}[]} parameters - The selected parameters.
 * @param {string} omniclassName - The name of the omniclass.
 * @param {AbortSignal} signal - The signal to abort the request.
 * @param {boolean} expanded - Whether to return manufacturers for the parent omniclass.
 *                             If no parent omniclass exists, this flag is ignored.
 *
 * @returns {string[]} A list of manufacturer titles associated with the specified omniclass and parameter-values.
 */
export const filterManufacturers = async (
  parameters: SelectedParameter[],
  omniclassName: string,
  signal: AbortSignal,
  expanded: boolean = false
): Promise<string[]> => {
  // remove empty parameters
  const filteredParameters = parameters.filter(parameter => parameter.value !== '');

  const body = {
    omniclass: omniclassName,
    parameters: filteredParameters
  }

  const response = await fetch(`${apiEndpoints.manufacturers_by_selected_parameters}?expanded=${expanded}`, {
    signal, // this is used to cancel the previous request
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
  return await response.json()
}


/**
 * Create a new manufacturer.
 *
 * @param manufacturer - The manufacturer data.
 *
 * @throws {Error} - If the request fails
 */
export const createManufacturer = async (manufacturer: AnnotatedManufacturer) => {
  const response = await fetch(adminEndpoints.manufacturer.create, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(manufacturer)
  });
  await checkSuccess(response);
}


/**
 * Update a manufacturer.
 *
 * This accepts the original manufacturer title in the event that the title is updated.
 *
 * @param manufacturer - The manufacturer data.
 * @param title - Original manufacturer title.
 *
 * @throws {Error} - If the request fails
 */
export const updateManufacturer = async (manufacturer: AnnotatedManufacturer, title: string) => {
  const body = {
    title: title,
    data: manufacturer
  }
  const response = await fetch(adminEndpoints.manufacturer.data, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
  await checkSuccess(response);
}


/**
 * Delete a manufacturer.
 *
 * @param title - The manufacturer title.
 *
 * @throws {Error} - If the request fails
 */
export const deleteManufacturer = async (title: string) => {
  const response = await fetch(adminEndpoints.manufacturer.delete, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({title: title})
  });
  await checkSuccess(response);
}


export const getAllManufacturerTitles = async (): Promise<string[]> => {
  const response = await fetch(
    adminEndpoints.manufacturer.all,  {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  return await response.json();
}


/**
 * Get logos for all manufacturers which have manufacturer specific variants
 *
 * This is used for displaying the manufacturer logos in the `SearchInput` element.
 */
export const getLogosWithOmniclassVariants = async (): Promise<Record<string, string>> => {
  const response = await fetch(
    apiEndpoints.manufacturer_logos_with_variants,  {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  return await response.json();
}


/**
 * Get relevant manufacturer data for the given omniclass.
 *
 * The data returned includes the URL and the logo. Used in the `StartPage`.
 *
 * @returns {Promise<Record<string, string>} A map of manufacturer titles to their respective logos for the given omniclass.
 */
export const getManufacturerOverview = async (omniclass: string): Promise<AnnotatedManufacturer[]> => {
  const url = `${apiEndpoints.manufacturer_logos_for_omniclass}/${omniclass}`
  const response = await fetch(
    url,  {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  return await response.json();
}


/**
 * Get all manufacturers with missing logos.
 *
 * Used for the `UploadManufacturerLogos` page to display manufacturers with missing logos.
 *
 * @returns {Promise<AnnotatedManufacturer[]>} A list of manufacturers with missing logos (includes title, URL)
 */
export const getManufacturersWithMissingLogos = async (omniclassFragment: string): Promise<AnnotatedManufacturer[]> => {
  const url = `${adminEndpoints.manufacturer.utilities.missing_logos}?q=${omniclassFragment}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return await response.json();
}


/**
 * Updates a manufacturer's logo on the server.
 *
 * Used in the `UploadManufacturerLogos` page to upload an individual manufacturer's logo.
 *
 * @param {string} manufacturerTitle - Manufacturer title
 * @param {string} logoDataUrl - The logo image data as a base64 encoded string
 *
 * @throws {Error} - If the request fails or the response is not successful
 */
export const updateManufacturerLogo = async (manufacturerTitle: string, logoDataUrl: string): Promise<void> => {
  const data = {
    title: manufacturerTitle,
    logo: logoDataUrl
  };
  const response = await fetch(adminEndpoints.manufacturer.utilities.upload_logos, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  await checkSuccess(response);
};
