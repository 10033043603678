import {AnnotatedManufacturer, Manufacturer} from "@/types/Manufacturer";
import {Link} from "react-router-dom";
import {Factory} from "lucide-react";
import {useEffect, useState} from "react";
import {getManufacturerOverview} from "@/api/services/manufacturerService";
import {LinearProgress} from "@/components/LinearProgress";
import pako from "pako";


// NOTE: pako was used for compression because as of 8/28/2024, the Compression Stream API is not recognized by the TypeScript compiler.

/**
 * Function to compress data using pako
 */
function compressData(data: string): Uint8Array {
  return pako.gzip(data);
}

/**
 * Function to decompress data using pako
 */
function decompressData(data: Uint8Array): string {
  return pako.ungzip(data, { to: "string" });
}

const ManufacturerCell = ({manufacturer, manufacturerLogo}: {manufacturer: Manufacturer, manufacturerLogo?: string | null}) => {
  return (
    <Link
      to={manufacturer.url ? manufacturer.url : ""}
      target={manufacturer.url ? "_blank" : ""}
    >
      <div key={manufacturer.title} className={"flex flex-col items-center justify-end gap-2 h-full p-4 rounded-2xl hover:bg-primary/10 hover:shadow-md"}>
        {(manufacturerLogo === undefined || manufacturerLogo === null) && <Factory className={"h-20 w-max stroke-1"} />}
        {manufacturerLogo && (
          <div className={"flex-grow flex items-center"}>
            <img src={manufacturerLogo} alt={manufacturer.title} className={"max-h-20"} />
          </div>
        )}
        <span className={"text-sm text-center"}>{manufacturer.title}</span>
      </div>
    </Link>
  )
}

interface LogoGridProps {
  omniclassName: string;
}

export const LogoGrid = ({ omniclassName }: LogoGridProps) => {
  const [manufacturers, setManufacturers] = useState<AnnotatedManufacturer[]>([]);
  const [loading, setLoading] = useState(true);
  const [showGrid, setShowGrid] = useState(false);

  /**
   * Show the grid after a short delay
   *
   * This is to prevent the loading element from flashing if the data is retrieved from the cache.
   */
  useEffect(() => {
    const timer = setTimeout(() => setShowGrid(true), 100);
    return () => clearTimeout(timer);
  }, []);

  /**
   * Fetch the manufacturers for the given omniclass
   *
   * If the data is already stored in Cache Storage, it will be used instead of fetching from the API.
   * The omniclass name is stored in the Cache Storage to ensure that the data is only used for the correct omniclass.
   * Cached data remains valid for 1 hour.
   */
  useEffect(() => {
    const cacheName = 'logo_grid_cache';
    const cacheKey = `logo_grid_manufacturers_${omniclassName}`;
    const cacheExpiry = 3600000; // 1 hour in milliseconds

    // fetch from endpoint and return true if found in cache
    async function fetchFromCache() {
      const cache = await caches.open(cacheName);
      const cachedResponse = await cache.match(cacheKey);
      if (cachedResponse) {
        const cachedData = await cachedResponse.json();
        const { timestamp, compressedData } = cachedData;

        // check if cache is still valid
        if (Date.now() - timestamp < cacheExpiry) {
          const decompressedData = decompressData(new Uint8Array(compressedData));
          const storedManufacturers = JSON.parse(decompressedData);

          setManufacturers(storedManufacturers);
          setLoading(false);
          return true;
        }
      }
      return false;
    }

    // fetch from endpoint then store in cache
    async function fetchData() {
      setManufacturers([]);
      setLoading(true);

      const data = await getManufacturerOverview(omniclassName);
      setManufacturers(data);
      const compressedData = compressData(JSON.stringify(data));

      const cache = await caches.open(cacheName);
      const response = new Response(JSON.stringify({ timestamp: Date.now(), compressedData: Array.from(compressedData) }));
      await cache.put(cacheKey, response);
      setLoading(false);
    }

    fetchFromCache().then((foundInCache) => {
      if (!foundInCache) {
        fetchData();
      }
    });
  }, [omniclassName]);

  if (!showGrid) return null;

  return (
    <div className={"flex flex-col gap-4 items-center px-8"}>
      <h1 className={"text-gray-500 text-xl md:text-2xl text-center w-full"}>Manufacturers</h1>
      <p className={"text-gray-400 font-sm text-center"}>
        Here are manufacturer quick links {omniclassName}. Click a manufacturer to visit their homepage.
      </p>
      {loading && (
        <div className={"w-full md:w-[75%] lg:w-[60%] text-center"}>
          <span>Loading...</span>
          <LinearProgress />
        </div>
      )}
      <div className={"grid grid-cols-2 md:grid-cols-3 gap-16 md:px-32 pb-16"}>
        {manufacturers.map((manufacturer) => (
          <ManufacturerCell
            key={manufacturer.title}
            manufacturer={manufacturer}
            manufacturerLogo={manufacturer.logo}
          />
        ))}
      </div>
    </div>
  )
}