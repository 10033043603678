import React from "react";
import { AnnotatedManufacturer } from "@/types/Manufacturer";
import {Skeleton} from "@/components/ui/Skeleton";
import {EmailRequestDialog} from "@/app/components/EmailRequestDialog";
import {SelectedParameter} from "@/types/Parameters";
import { Button } from "@/components/ui/Button";
import {Link} from "react-router-dom";
import {HomeIcon, InformationCircleIcon} from "@heroicons/react/24/outline";

interface ManufacturerPanelProps {
  manufacturer: AnnotatedManufacturer | null;
  selectedParameters: SelectedParameter[];
  omniclassName: string;
}

export const ManufacturerPanel = ({ manufacturer, selectedParameters, omniclassName }: ManufacturerPanelProps) => {
  if (!manufacturer) {
    return (
      <div className={"flex gap-4 items-end justify-start px-8 pb-4"}>
        <Skeleton className={"h-16 w-16"} />
        <div className={"flex flex-col gap-2"}>
          <Skeleton className={"h-4 w-32"} />
          <Skeleton className={"h-10 w-64 max-w-64"} />
        </div>
      </div>
    )
  }
  return (
    <div className={"flex flex-col gap-4"}>
      {/* Manufacturer Info */}
      <div className={"flex gap-4 items-end justify-start px-8 pb-4"}>
        {manufacturer.logo &&
            <img src={manufacturer.logo} alt={`${manufacturer.title} logo`} className={"max-h-16"}/>}
        <div className={"flex flex-col gap-2"}>
          <span className={"text-base font-light"}>Product By:</span>
          <h2 className={"text-2xl"}>
            {manufacturer.title}
          </h2>
        </div>
      </div>

      <EmailRequestDialog
        manufacturers={[manufacturer.title]}
        omniclassName={omniclassName}
        selectedParameters={selectedParameters}
        buttonText={"Request Product Information"}
      />

      <div className={"flex flex-col gap-2"}>

        <Button variant={"ghost"} className={"flex items-center"} asChild>
          <Link to={`/manufacturer/${manufacturer.title}`} className={""} target="_blank" rel="noreferrer">
            <InformationCircleIcon className={"h-4 w-4 mr-1"} />
            See Manufacturer Details
          </Link>
        </Button>

        <Button variant={"ghost"} className={"flex items-center"} asChild>
          <Link to={`/manufacturer/${manufacturer.title}`} className={""} target="_blank" rel="noreferrer">
            <HomeIcon className={"h-4 w-4 mr-1"} />
            Manufacturer Homepage
          </Link>
        </Button>

      </div>
    </div>
  )
}