import {SectionTitle} from "@/app/pages/ManufacturerDetails/components/SectionTitle";
import {AnnotatedManufacturer} from "@/types/Manufacturer";
import {VerifiedTag} from "@/app/components/VerifiedTag";


export const DetailHeader = ({manufacturer}: { manufacturer: AnnotatedManufacturer }) => (
  /* TODO: insert logo beside title */
  <div className={"flex flex-row gap-2 items-center mb-4"}>
    <SectionTitle className={"text-3xl"}>{manufacturer.title}</SectionTitle>

    {manufacturer.verified &&
      <VerifiedTag size={"header"} variant={"inverse"} />
    }
  </div>
)