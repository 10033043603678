import {AnnotatedManufacturer} from "@/types/Manufacturer";
import {useEffect, useState} from "react";
import {getAssociatedOmniclasses} from "@/api/services/manufacturerService";
import {AccordionContent, AccordionItem, AccordionTrigger} from "@/components/ui/accordian";

interface AssociatedOmniclassesProps {
  manufacturer: AnnotatedManufacturer;
}

export const AssociatedOmniclasses = ({manufacturer}: AssociatedOmniclassesProps) => {
  const [ associatedOmniclasses, setAssociationOmniclasses ] = useState<string[]>([]);

  // fetch associated omniclasses
  useEffect(() => {
    getAssociatedOmniclasses(manufacturer.title)
      .then(data => setAssociationOmniclasses(data))
  }, [manufacturer])

  return (
    <AccordionItem value={"associated-omniclasses"}>
      <AccordionTrigger>
        <span className={"mr-12"}>Products / Omniclasses</span>
      </AccordionTrigger>
      <AccordionContent>
        <p className={"pb-4 text-gray-600/90"}>
          These are all of the product types that {manufacturer.title} offers:
        </p>
        <ul className={"grid grid-cols-3 gap-2"}>
          {associatedOmniclasses.map(omniclass => (<li className={"text-gray-500"} key={omniclass}>{omniclass}</li>))}
          {associatedOmniclasses.length === 0 && <li className={"text-gray-500"}>No associated omniclasses</li>}
        </ul>
      </AccordionContent>
    </AccordionItem>
  )
}