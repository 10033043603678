import React, {useState, useEffect} from "react";

import LogoFull from "@/app/assets/logo_full.png";
import {getAllUserFacingOmniclasses} from "@/api/services/omniclass";
import {OmniclassSearchInput} from "@/components/searches/OmniclassSearchInput";
import {GenerationCountdown} from "@/app/pages/StartPage/components/GenerationCountdown";
import {encode_readable_url} from "@/lib/formatting";
import {useNavigate, useLocation} from "react-router-dom";
import {LogoGrid} from "@/app/pages/StartPage/components/LogoGrid";
import {Omniclass} from "@/types/Omniclass";

/**
 * If the selected omniclass contains any of these fragments, the logo grid will be shown.
 */
const ALLOWED_OMNICLASSES: string[] = ["windows", "bollards"];

/**
 * Start page for the application
 */
export const StartPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showCountdown, setShowCountdown] = React.useState(false);
  const [selectedOption, setSelectedOption] = useState<Omniclass | null>(null);

  const showLogoGrid = selectedOption !== null &&
    ALLOWED_OMNICLASSES.some(fragment => selectedOption.name.toLowerCase().includes(fragment));

  /**
   * Load `selectedOption` from the URL search params
   */
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const storedOption = params.get('selectedOption')

    if (storedOption) {
      // retrieve omniclass name from the URL search params
      const incompleteOption: Omniclass = {name: storedOption, number: ""};
      setSelectedOption(incompleteOption);
    }
  }, [location.search]);

  /**
   * Store `selectedOption` in the URL search params
   */
  useEffect(() => {
    if (selectedOption) {
      const params = new URLSearchParams(location.search);
      params.set('selectedOption', selectedOption.name);
      navigate({ search: params.toString() }, { replace: true });
    }
  }, [selectedOption, navigate, location.search]);

  /**
   * Navigate to the omniclass page (main app page)
   *
   * If the page is taking more than 500ms to load, the omniclass is assumed to be being generated, and a countdown is shown.
   *
   * @param omniclass - the omniclass to navigate to
   * @param manufacturerTitle (optional) - if provided, the page will be navigated to the manufacturer-specific omniclass page
   */
  const gotoOmniclass = (omniclass: string, manufacturerTitle?: string) => {
    // Show the countdown after a short delay (assume the omniclass is being generated)
    setTimeout(() => setShowCountdown(true), 500);

    if (manufacturerTitle) {
      navigate(`/omniclass/${encode_readable_url(omniclass)}/by/${manufacturerTitle}`);
      return;
    }
    navigate(`/omniclass/${encode_readable_url(omniclass)}`);
  }

  /**
   * Wrapper for setSelectedOption that navigates to the advanced search if the selected option is not in the allowed list
   * of omniclasses.
   *
   * @param value - the value to set as the selected option
   */
  const setSelectedOptionWrapper = (value: Omniclass | null) => {
    if (value && !ALLOWED_OMNICLASSES.some(fragment => value.name.toLowerCase().includes(fragment))) {
      // TODO: set `selectedOption` and other states to update before navigating
      gotoOmniclass(value.name);
    } else {
      setSelectedOption(value);
    }
  }

  return (
    <div className={"min-h-[100vh] flex flex-col justify-center"}>

      <div className={`flex flex-col gap-4 w-full items-center`}>
        <div className={"flex-grow"}>
          <img src={LogoFull}
               alt={"logo"}
               className={`px-2 max-w-[32ch] ${selectedOption ? 'pt-16' : ''}`}/>
        </div>
        <h1 className={`text-2xl font-medium text-primary`}>SCOUT&#8482; Product Search</h1>
        <div className={"w-full pt-4 md:pt-0 md:w-[75%] lg:w-[60%] xl:w-[50%]"}>
          <OmniclassSearchInput
            fetchOptions={getAllUserFacingOmniclasses}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOptionWrapper}
            handleAdvancedSearch={gotoOmniclass}
          />
        </div>
        {showCountdown && <GenerationCountdown />}

        {showLogoGrid && selectedOption && <LogoGrid omniclassName={selectedOption.name} />}
      </div>

      {}
    </div>);
}